<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <!--loading-->
        <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

        <div class="overflow-auto w-full">
          <p class="text-sm mb-5">Payment request ini terdiri dari {{ items.length }} transaksi.</p>
          <table class="table-auto table-sticky border-collapse w-full">
            <!-----------TABLE HEAD------------>
            <thead>
              <tr class="d-theme-dark-bg">
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs whitespace-no-wrap">#</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs whitespace-no-wrap">No. Trx</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs whitespace-no-wrap">Proyek</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs whitespace-no-wrap">Rekanan</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs whitespace-no-wrap">Total</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs whitespace-no-wrap">Total Paid</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs whitespace-no-wrap">Total Balance</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs whitespace-no-wrap">Remark</th>
              </tr>
            </thead>

            <!-----------TABLE BODY------------>
            <tbody v-for="group in groups" :key="group.uuid">
              <!--group-->
              <tr>
                <td class="border border-solid d-theme-border-grey-light p-2 font-bold text-xs" colspan="14">{{ group.name }}</td>
              </tr>
              <!--items-->
              <template v-if="group.expanded">
                <tr v-for="(item, index) in group.items" :key="item.uuid">
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light whitespace-no-wrap text-left">{{ index + 1 }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light whitespace-no-wrap text-left underline cursor-pointer" @click="viewDetailTransaction(item)">{{ item.no_trx }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light whitespace-no-wrap text-left">{{ item.kode_proyek }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light whitespace-no-wrap text-left">{{ item.nama_rekanan }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light whitespace-no-wrap text-right">{{ item.total | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light whitespace-no-wrap text-right">{{ item.total_paid | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light whitespace-no-wrap text-right">{{ item.total_balance | idr }}</td>
                  <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light whitespace-no-wrap text-left">{{ item.remark }}</td>
                </tr>
              </template>
            </tbody>

            <!-----------TABLE FOOT------------>
            <tfoot>
              <tr class="d-theme-dark-bg">
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs text-right">{{ summary.grandTotal | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs text-right">{{ summary.grandTotalPaid | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs text-right">{{ summary.grandTotalBalance | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs text-right"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!--modals-->
    <ReimburseView v-if="modalDetailTransaction.modalType === 'REIMBURSE'"
                   :idReimburse="modalDetailTransaction.idTrx"
                   :isActive.sync="modalDetailTransaction.active"/>

    <ApOtherView v-if="modalDetailTransaction.modalType === 'AP OTHER'"
                 :idApOther="modalDetailTransaction.idTrx"
                 :isActive.sync="modalDetailTransaction.active"/>
  </div>
</template>

<script>
import ApRepository from '@/repositories/accounting/ap-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'TabPaymentVouchers',
  props: {
    isActive: { required: true, type: Boolean }
  },
  components: {
    ReimburseView: () => import('@/views/pages/procurement/reimburse/ReimburseView'),
    ApOtherView: () => import('@/views/pages/accounting/account-payable/ap-other/ApOtherView')
  },
  watch: {
    isActive (active) {
      if (active && !this.isDataInited) {
        this.initData()
      }
    }
  },
  computed: {
    summary () {
      return {
        grandTotal: _.sumBy(this.items, item => parseFloat(item.total)),
        grandTotalPaid: _.sumBy(this.items, item => parseFloat(item.total_paid)),
        grandTotalBalance: _.sumBy(this.items, item => parseFloat(item.total_balance))
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      items: [],
      groups: [],
      modalDetailTransaction: {
        modalType: null,
        idTrx: null,
        active: false
      }
    }
  },
  methods: {
    initData () {
      this.getInitData()
      this.isDataInited = true
    },

    getInitData () {
      this.isLoading = true

      const idPaymentRequest = this.$route.params.idPaymentRequest
      ApRepository.getPaymentRequestTransactions(idPaymentRequest)
        .then(response => {
          this.items = _.cloneDeep(response.data.data)
          this.groups = this.buildGroups(response.data.data)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildGroups (data) {
      const generateItems = (idPv) => {
        return _.map(_.filter(data, item => item.id_pv === idPv), item => {
          item.uuid = uuid()
          return item
        })
      }

      const generateGroups = () => {
        return _.map(_.uniqBy(data, item => item.id_pv), item => {
          return {
            uuid: uuid(),
            name: `${item.no_pv}  -  ${item.tipe_pv}`,
            items: generateItems(item.id_pv),
            expanded: true
          }
        })
      }

      return generateGroups()
    },

    viewDetailTransaction (item) {
      const type = item.tipe_pv
      let isActionOpenModal = false
      let modalType = null
      let modalIdTrx = null
      let isActionOpenNewTab = false
      let routeData = null

      // decide action
      if (['SPK PAYMENT', 'SPK PPH PAYMENT'].includes(type)) {
        isActionOpenNewTab = true
        routeData = { name: 'proyek.spk.show', params: { idSpk: item.id_trx_h } }
      } else if (['PO PAYMENT', 'PO PPH PAYMENT'].includes(type)) {
        isActionOpenNewTab = true
        routeData = { name: 'proyek.po.show', params: { idPo: item.id_trx_h } }
      } else if (['KOMISI PAYMENT', 'KOMISI PPH PAYMENT'].includes(type)) {
        isActionOpenNewTab = true
        routeData = { name: 'marketing.komisi.show', params: { idKomisi: item.id_trx_h } }
      } else if (['KONTRAK PPN PAYMENT', 'KONTRAK PPH PAYMENT'].includes(type)) {
        isActionOpenNewTab = true
        routeData = { name: 'marketing.kontrak.detail', params: { idKontrak: item.id_trx_h } }
      } else if (['REIMBURSE PAYMENT'].includes(type)) {
        isActionOpenModal = true
        modalType = 'REIMBURSE'
        modalIdTrx = item.id_trx_h
      } else if (['AP OTHER PAYMENT'].includes(type)) {
        isActionOpenModal = true
        modalType = 'AP OTHER'
        modalIdTrx = item.id_trx_h
      }

      // execute action
      if (isActionOpenNewTab) {
        const route = this.$router.resolve(routeData)
        window.open(route.href, '_blank')
      } else if (isActionOpenModal) {
        this.modalDetailTransaction.modalType = modalType
        this.modalDetailTransaction.idTrx = modalIdTrx
        setTimeout(() => (this.modalDetailTransaction.active = true), 100)
      } else {
        this.notifyWarning('Transaksi ini tidak memiliki detail transaksi.')
      }
    }
  }
}
</script>
